@mixin only-mobile {
    @media (max-width: #{$tablet-width}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin only-tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin only-desktop {
    @media (min-width: #{$desktop-width}) and (max-width: #{$large-width}) {
        @content;
    }
}

@mixin large {
    @media (min-width: #{$large-width}) {
        @content;
    }
}
