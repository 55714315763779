@import-normalize;
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-image: url('./assets/orion-nebula.jpg'); */
    height: "100vh";
    margin: 0;
    padding: 0;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
    outline: none;
    text-decoration: none;
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
