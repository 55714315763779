/********************************************
	BREAKPOINT WIDTHS
********************************************/
$tablet-width: 768px;
$desktop-width: 960px;
$large-width: 1280px;

/********************************************
	FONTS
********************************************/
$default-font-family: "Roboto", sans-serif;
$default-font-size: 16px;

/********************************************
	COLOURS
********************************************/
$color-primary: rgb(255, 255, 255);
$color-secondary: #5b7481;

$color-background: #f5f5f5;

$color-stroke-primary: #bebebe;
$color-stroke-secondary: #aeaeae;
